import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import _range from 'lodash/range';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import { BLACK } from '../../stylesheets/colors';

const useStyles = makeStyles((theme) => ({
  threeDotButton: {
    padding: 0,
    '& svg': {
      width: '6px',
      margin: '0 1px',
      color: BLACK,
    },
  },
  active: {
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
}));

const ThreeDotButton = forwardRef(({ onClick, anchorEl, disabled }, ref) => {
  const classes = useStyles();

  return (
    <IconButton
      data-testid="customPopoverButton"
      disabled={disabled}
      ref={ref}
      onClick={onClick}
      className={`${classes.threeDotButton} ${anchorEl ? classes.active : ''}`}
    >
      {_range(3).map((v) => (
        <TripOriginOutlinedIcon fontSize="large" key={v} />
      ))}
    </IconButton>
  );
});

ThreeDotButton.displayName = 'ThreeDotButton';

ThreeDotButton.defaultProps = {
  anchorEl: null,
  disabled: false,
};

ThreeDotButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({}),
  disabled: PropTypes.bool,
};
export default memo(ThreeDotButton);
